import React from 'react';
import { X } from 'lucide-react';
import VideoTutorial from "../assets/gaia-demo.mp4";

const TutorialPopup = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div
                className="absolute inset-0 bg-black bg-opacity-75"
                onClick={onClose}
            />
            <div className="relative bg-gray-900 rounded-lg w-full max-w-4xl mx-4">
                <button
                    onClick={onClose}
                    className="absolute -top-2 -right-2 p-2 bg-gray-800 rounded-full hover:bg-gray-700 transition-colors"
                >
                    <X className="w-5 h-5" />
                </button>
                <div className="relative pt-[56.25%]">
                    <video
                        className="absolute inset-0 w-full h-full rounded-lg"
                        controls
                        autoPlay
                        src={VideoTutorial}
                    >
                        <track kind="captions" />
                        Your browser does not support the video element.
                    </video>
                </div>
            </div>
        </div>
    );
};

export default TutorialPopup;