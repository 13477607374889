import { useState, useEffect } from "react";
import { FaLanguage, FaPen, FaUpload } from 'react-icons/fa';

const Sota = ({ user }) => {
    const [selectedModel, setSelectedModel] = useState(null);
    const [srcLang, setSrcLang] = useState("");
    const [tgtLang, setTgtLang] = useState("");


    useEffect(() => {
        if (selectedModel) {
            setSrcLang(selectedModel.src_lang);
            setTgtLang(selectedModel.tgt_lang);
        }
    }, [selectedModel]);

    useEffect(() => {
        if (selectedModel) {
            setSrcLang(selectedModel.src_lang);
            setTgtLang(selectedModel.tgt_lang);
        }
    }, [selectedModel]);

    return (
        <div translate="no" className="max-w-4xl mx-auto p-8 bg-gradient-to-br from-purple-50 via-indigo-50 to-blue-50">
            <CreateModel user={user} />
        </div>
    );
}

export default Sota;

const CreateModel = ({ user }) => {
    const [srcLang, setSrcLang] = useState("");
    const [tgtLang, setTgtLang] = useState("");
    const [customPrompt, setCustomPrompt] = useState("");
    const [jsonFile, setJsonFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type === "application/json") {
            setJsonFile(file);
            setError("");
        } else {
            setError("Please upload a valid JSON file");
            setJsonFile(null);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");
        setSuccess(false);

        if (!jsonFile || !srcLang || !tgtLang) {
            setError("Please fill in all required fields");
            setLoading(false);
            return;
        }

        const formData = new FormData();
        formData.append("parallel_sentences", jsonFile);
        formData.append("src_lang", srcLang.toLocaleLowerCase());
        formData.append("tgt_lang", tgtLang.toLocaleLowerCase());
        formData.append("custom_prompt", customPrompt);
        formData.append("uid", user.uid);

        try {
            const response = await fetch("https://fst.gaia-ml.com/api/create-model/", {
                method: "POST",
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Failed to create model");
            }

            const data = await response.json();
            setSuccess(true);
            setSrcLang("");
            setTgtLang("");
            setCustomPrompt("");
            setJsonFile(null);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="max-w-4xl mx-auto p-8 bg-gradient-to-br from-purple-50 via-indigo-50 to-blue-50">
            <p className="pb-8">
              Before starting please take a look at this  <a href="https://maresone.s3.us-east-1.amazonaws.com/sample_dataset.json" target="_blank" className="text-blue-600 underline hover:text-blue-800 cursor-pointer">sample dataset</a>. Download and examine it before creating your model.
            </p>
            <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                        <label className="block text-base font-medium text-indigo-700 mb-2 flex items-center">
                            <FaLanguage className="mr-2" /> Source Language
                        </label>
                        <input
                            type="text"
                            value={srcLang}
                            onChange={(e) => setSrcLang(e.target.value)}
                            className="w-full rounded-xl border border-indigo-200 p-3 outline-none"
                            placeholder="Source language name"
                            required
                        />
                    </div>

                    <div>
                        <label className="block text-base font-medium text-indigo-700 mb-2 flex items-center">
                            <FaLanguage className="mr-2" /> Target Language
                        </label>
                        <input
                            type="text"
                            value={tgtLang}
                            onChange={(e) => setTgtLang(e.target.value)}
                            className="w-full rounded-xl border border-indigo-200 p-3 outline-none"
                            placeholder="Target language name"
                            required
                        />
                    </div>
                </div>
 
                <div>
                    <label className="block text-base font-medium text-indigo-700 mb-2 flex items-center">
                        <FaPen className="mr-2" /> Description
                    </label>
                    <textarea
                        value={customPrompt}
                        onChange={(e) => setCustomPrompt(e.target.value)}
                        rows={4}
                        className="w-full rounded-xl border border-indigo-200 p-4 outline-none"
                        placeholder="Enter a description for your model"
                    />
                </div>

                <div>
                    <label className="block text-base font-medium text-indigo-700 mb-2 flex items-center">
                        <FaUpload className="mr-2" /> Parallel Sentences JSON
                    </label>
                    <input
                        type="file"
                        accept="application/json"
                        onChange={handleFileChange}
                        className="w-full rounded-xl border border-indigo-200 p-3 outline-none file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
                        required
                    />
                </div>

                <div className="flex justify-center">
                    <button
                        type="submit"
                        disabled={loading || !jsonFile}
                        className={`rounded-full bg-gradient-to-r from-indigo-600 to-blue-600 py-3 px-8 text-white font-semibold shadow-lg hover:from-indigo-500 hover:to-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-offset-2 transition ${(loading || !jsonFile) && "opacity-50 cursor-not-allowed"
                            }`}
                    >
                        {loading ? "Creating Model..." : "Create Model"}
                    </button>
                </div>
            </form>

            {error && (
                <div className="mt-6 p-4 bg-red-100 border border-red-300 rounded-xl text-red-700">
                    {error}
                </div>
            )}

            {success && (
                <div className="mt-6 p-4 bg-green-100 border border-green-300 rounded-xl text-green-700">
                    Model created successfully!
                </div>
            )}
        </div>
    );
}