import React, { useState, useEffect } from 'react';
import { getAuth, updateProfile } from "firebase/auth";
import { Globe, Settings, BarChart, Users, User, PlusCircle, Trash2, Clock, Pen, ArrowLeft, Menu, X, ChevronRightSquare } from 'lucide-react';
import CreateModel from './createModel';
import {PiLightningABold} from "react-icons/pi"
import Training from './training';
import { Check, Copy } from 'lucide-react';
import { initializeApp } from "firebase/app";
import { Link } from 'react-router-dom';
import { getFirestore, collection, getDocs, query, where, getDoc, setDoc, addDoc, serverTimestamp, onSnapshot, doc, updateDoc, arrayRemove } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAOYiOFVlyrhxtWYxn8J4yvCbFOdSvXYkI",
    authDomain: "gaia-b6c3d.firebaseapp.com",
    projectId: "gaia-b6c3d",
    storageBucket: "gaia-b6c3d.appspot.com",
    messagingSenderId: "143445699815",
    appId: "1:143445699815:web:58ff7938215492a1414642",
    measurementId: "G-MPTVCFN0TJ"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const Dashboard = ({ user, models, trainingStatus, setTrainingStatus, sotaModels }) => {
    const [activeSection, setActiveSection] = useState('Dashboard');
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const SidebarItem = ({ icon: Icon, title }) => (
        <div
            className={`flex items-center p-3 text-gray-700 hover:bg-blue-100 cursor-pointer ${activeSection === title ? 'bg-blue-100' : ''}`}
            onClick={() => {
                setActiveSection(title);
                setIsSidebarOpen(false); 
            }}
        >
            <Icon size={20} className="mr-3" />
            <span>{title}</span>
        </div>
    );

    const StatCard = ({ title, value }) => (
        <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-sm text-gray-500">{title}</h3>
            <p className="text-2xl font-semibold">{value}</p>
        </div>
    );

    const renderActiveSection = () => {

        switch (activeSection) {
            case 'Builder':
                return <CreateModel user={user} sotaModels={sotaModels} />;
            case 'Legacy':
                return <Training user={user} trainingStatus={trainingStatus} setTrainingStatus={setTrainingStatus} />;
            case 'API':
                return <API models={sotaModels} />;
            case 'Team':
                return <Team />;
            case 'Account':
                return <Account user={user} />;
            default:
                return (
                    <div className="flex-1 p-4 sm:p-8 overflow-auto">
                        <div className="flex justify-between items-center mb-6">
                            <h1 className="text-2xl font-semibold">Welcome, {user?.displayName}</h1>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
                            <StatCard title="Active Models" value={models.length} />
                            <StatCard title="Languages" value={new Set(models.flatMap(model => [model.data.sourceLang, model.data.targetLang])).size} />
                            <StatCard title="Requests last month" value="0" />
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                            <div className="bg-white p-4 rounded-lg shadow flex items-center justify-between">
                                <div onClick={() => setActiveSection("Training")} className="flex cursor-pointer items-center">
                                    <PlusCircle size={24} className="mr-3 text-blue-600" />
                                    <span>New model</span>
                                </div>
                                <button onClick={() => setActiveSection("Builder")} className="bg-blue-500 text-white px-4 py-2 rounded font-semibold hover:bg-blue-600">Create</button>
                            </div>
                        </div> 
                        <div className="grid grid-cols-1 p-4 sm:grid-cols-2">
                            {sotaModels.map((model) => (
                                <ModelCard key={model.id} model={model} />
                            ))}
                        </div>
                    </div>
                );
        }
    };

    return (
        <div translate="no" className="flex h-screen bg-gray-100">
            {isSidebarOpen && (
                <div className="fixed inset-0 z-40 sm:hidden">
                    <div className="absolute inset-0 bg-black opacity-50" onClick={toggleSidebar}></div>
                    <div className="absolute inset-y-0 left-0 w-64 bg-white shadow-lg p-4 overflow-y-auto">
                        <div className="flex items-center justify-between mb-6">
                            <h2 className="text-xl font-semibold">Menu</h2>
                            <button onClick={toggleSidebar}>
                                <X size={24} />
                            </button>
                        </div>
                        <nav>
                            <SidebarItem icon={Globe} title="Dashboard" />
                            <SidebarItem icon={PiLightningABold} title="Builder" />
                            <SidebarItem icon={Settings} title="Legacy" />
                            <SidebarItem icon={BarChart} title="API" />
                            <SidebarItem icon={Users} title="Team" />
                            <SidebarItem icon={User} title="Account" />
                        </nav>
                    </div>
                </div>
            )}

            <div className="hidden sm:flex sm:flex-shrink-0">
                <div className="w-64 bg-white shadow-lg h-full">
                    <nav className="mt-4">
                        <SidebarItem icon={Globe} title="Dashboard" />
                        <SidebarItem icon={PiLightningABold} title="Builder" />
                        <SidebarItem icon={Settings} title="Legacy" />
                        <SidebarItem icon={BarChart} title="API" />
                        <SidebarItem icon={Users} title="Team" />
                        <SidebarItem icon={User} title="Account" />
                    </nav>
                </div>
            </div>

            <div className="flex-1 flex flex-col">
                <header className="flex items-center justify-between p-4 bg-white shadow-sm sm:hidden">
                    <button onClick={toggleSidebar}>
                        <ChevronRightSquare size={24} />
                    </button>
                    <h1 className="text-xl font-semibold">Dashboard</h1>
                    <div></div>
                </header>
                <main className="flex-1 overflow-auto">
                    {renderActiveSection()}
                </main>
            </div>
        </div>
    );
};


const API = ({ models }) => {
    const [selectedModel, setSelectedModel] = useState('');
    const [copied, setCopied] = useState(false);

    const modelOptions = models?.map(model => ({
        value: model.id,
        label: `${model.src_lang} → ${model.tgt_lang}`,
        ...model
    })) || [];

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const getApiExample = (model) => {
        return `curl -X POST https://fst.gaia-ml.com/api/translate/ \\
    -H "Content-Type: application/json" \\
    -d '{
        "text": "Hello, world!",
        "model_id": "${model?.id || 'model_id'}",
        "src_lang": "${model?.src_lang || 'src_lang'}",
        "tgt_lang": "${model?.tgt_lang || 'tgt_lang'}",
        "index_name": "${model?.index_name || 'index_name'}",
        "file_path": "${model?.file_path || 'file_path'}"
    }'`;
    };

    const selectedModelData = modelOptions.find(m => m.value === selectedModel);
    const apiExample = getApiExample(selectedModelData);

    return (
        <div className="p-8">
            <h2 className="text-2xl font-semibold mb-6">API Documentation</h2>
            <div className="bg-white p-6 rounded-lg shadow space-y-6">
                <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                        Select Model
                    </label>
                    <select
                        value={selectedModel}
                        onChange={(e) => setSelectedModel(e.target.value)}
                        className="w-full border-2 outline-none border-blue-500 rounded-md shadow-sm p-2"
                    >
                        <option value="">Select a model</option>
                        {modelOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>

                {selectedModel && (
                    <div className="space-y-4">
                        <div className="flex justify-between items-center">
                            <h3 className="text-xl font-semibold">API Example</h3>
                            <button
                                onClick={() => copyToClipboard(apiExample)}
                                className="flex items-center space-x-2 text-sm text-gray-600 hover:text-gray-900"
                            >
                                {copied ? <Check size={16} /> : <Copy size={16} />}
                                <span>{copied ? 'Copied!' : 'Copy'}</span>
                            </button>
                        </div>
                        <pre className="bg-gray-100 p-4 rounded overflow-x-auto">
                            <code>{apiExample}</code>
                        </pre>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Dashboard;

const Team = () => {
    const [newEditor, setNewEditor] = useState({ name: '', email: '' });
    const [teamMembers, setTeamMembers] = useState([]);
    const [error, setError] = useState('');

    const fetchTeams = async () => {
        const user = auth.currentUser;
        if (user) {
            const q = query(collection(db, 'teams'), where('members', 'array-contains', user.uid));
            const querySnapshot = await getDocs(q);
            const members = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setTeamMembers(members);
        }
    };

    useEffect(() => {
        fetchTeams();
    }, []);

    const handleInputChange = (e) => {
        setNewEditor({ ...newEditor, [e.target.name]: e.target.value });
    };

    const handleAddEditor = async (e) => {
        e.preventDefault();
        setError('');

        if (!newEditor.name || !newEditor.email) {
            setError('Please fill in all fields.');
            return;
        }

        try {
            const user = auth.currentUser;
            await addDoc(collection(db, 'teams'), {
                ownerId: user.uid,
                members: [user.uid],
                name: newEditor.name,
                email: newEditor.email,
                role: 'Editor'
            });

            setNewEditor({ name: '', email: '' });
            fetchTeams();
        } catch (error) {
            setError('Failed to add editor. Please try again.');
            console.error('Error adding editor:', error);
        }
    };

    const handleRemoveEditor = async (editorId) => {
        try {
            const user = auth.currentUser;
            const teamDoc = doc(db, 'teams', editorId);
            await updateDoc(teamDoc, {
                members: arrayRemove(user.uid)
            });
            fetchTeams();
        } catch (error) {
            setError('Failed to remove editor. Please try again.');
            console.error('Error removing editor:', error);
        }
    };

    return (
        <div className="p-6">
            <h2 className="text-2xl font-semibold mb-4">Teams</h2>
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Your Team</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Manage your team members here.</p>
                </div>
                <div className="border-t border-gray-200">
                    <ul className="divide-y divide-gray-200">
                        {teamMembers.map((member) => (
                            <li key={member.id} className="px-4 py-4 sm:px-6">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300"></div>
                                        <div className="ml-4">
                                            <div className="text-sm font-medium text-gray-900">{member.name}</div>
                                            <div className="text-sm text-gray-500">{member.email}</div>
                                        </div>
                                    </div>
                                    <div className="flex items-center">
                                        <div className="text-sm text-gray-500 mr-4">{member.role}</div>
                                        {member.ownerId !== auth.currentUser.uid && (
                                            <button
                                                onClick={() => handleRemoveEditor(member.id)}
                                                className="text-red-600 hover:text-red-800"
                                            >
                                                <Trash2 size={20} />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="mt-8 bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Add New Editor</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Invite a new team member as an editor.</p>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <form onSubmit={handleAddEditor} className="space-y-4">
                        <div>
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                            <input
                                type="text"
                                name="name"
                                id="name"
                                value={newEditor.name}
                                onChange={handleInputChange}
                                className="mt-1 focus:ring-indigo-500 p-2 border border-indigo-300 outline-none focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                        </div>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                value={newEditor.email}
                                onChange={handleInputChange}
                                className="mt-1 focus:ring-indigo-500 p-2 border border-indigo-300 outline-none focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                        </div>
                        {error && <p className="text-red-500 text-sm">{error}</p>}
                        <button
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Add Editor
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

const Account = ({ user }) => {
    const [editMode, setEditMode] = useState({
        email: false,
        fullName: false,
    });
    const [userData, setUserData] = useState({
        email: user?.email,
        fullName: user?.displayName,
    });

    useEffect(() => {
        const fetchUserData = async () => {
            if (user) {
                const userDocRef = doc(db, 'users', user.uid);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    const data = userDoc.data();
                    setUserData(prevState => ({
                        ...prevState,
                    }));
                }
            }
        };
        fetchUserData();
    }, [user]);

    const handleEdit = (field) => {
        setEditMode({ ...editMode, [field]: true });
    };

    const handleSave = async (field) => {
        setEditMode({ ...editMode, [field]: false });
        const userDocRef = doc(db, 'users', user.uid);

        try {
            if (field === 'fullName') {
                await updateProfile(auth.currentUser, { displayName: userData.fullName });
            }
            await updateDoc(userDocRef, { [field]: userData[field] });
        } catch (error) {
            console.error('Error updating user data:', error);
        }
    };

    const handleChange = (e, field) => {
        setUserData({ ...userData, [field]: e.target.value });
    };

    const renderField = (label, field, value) => (
        <li className="px-4 py-4 sm:px-6">
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <div className="text-sm font-medium text-gray-900">{label}</div>
                </div>
                <div className="flex items-center">
                    {editMode[field] ? (
                        <>
                            <input
                                type="text"
                                value={value}
                                onChange={(e) => handleChange(e, field)}
                                className="text-sm text-gray-500 mr-2 border rounded px-2 py-1"
                            />
                            <button
                                onClick={() => handleSave(field)}
                                className="text-green-600 hover:text-green-800"
                            >
                                Save
                            </button>
                        </>
                    ) : (
                        <>
                            <div className="text-sm text-gray-500 mr-2">{value}</div>
                            <button
                                onClick={() => handleEdit(field)}
                                className="text-blue-600 hover:text-blue-800"
                            >
                                <Pen size={16} />
                            </button>
                        </>
                    )}
                </div>
            </div>
        </li>
    );

    return (
        <div className="p-6">
            <h2 className="text-2xl font-semibold mb-4">Account Settings</h2>
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Profile</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Manage your account information here.</p>
                </div>
                <div className="border-t border-gray-200">
                    <ul className="divide-y divide-gray-200">
                        {renderField('Contact Email', 'email', userData.email)}
                        {renderField('Full Name', 'fullName', userData.fullName)}
                    </ul>
                </div>
            </div>
        </div>
    );
};

const toggleStyles = `
  .toggle-checkbox:checked {
    right: 0;
    border-color: #68D391;
  }
  .toggle-checkbox:checked + .toggle-label {
    background-color: #68D391;
  }
`;

const ModelCard = ({ model }) => {

    return (
        <Link to={`/dashboard/${model.id}`}
            key={model.id}
            className="p-6 bg-white rounded-xl shadow-sm border my-2 border-indigo-100 hover:border-indigo-300 cursor-pointer transition"
        >
            <h3 className="text-lg font-medium text-indigo-600 mb-2">
                {model.src_lang} → {model.tgt_lang}
            </h3>
            <p className="text-sm text-gray-600">
                Created: {new Date(model.created_at.seconds * 1000).toLocaleDateString()}
            </p>
            {model.custom_prompt && (
                <p className="mt-2 text-sm text-gray-500">
                    {model.custom_prompt}
                </p>
            )}
        </Link>
    );
};