import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    Menu, X, FileText, Box, Home, LogOut, LogIn
} from 'lucide-react';

const Header = ({ user, signInWithGoogle, signInWithEmail, signOut }) => {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const isDashboardRoute = location.pathname.startsWith("/dashboard");

    return (
        <header translate="no" className={`relative ${isDashboardRoute ? 'bg-white text-black' : 'bg-gradient-to-r from-gray-900 via-gray-800 to-black text-white'}`}>
            {!isDashboardRoute && (
                <div className="absolute inset-0 bg-gradient-to-r from-blue-900 via-transparent to-purple-900 opacity-20 pointer-events-none"></div>
            )}
            <nav className="container mx-auto px-4 sm:px-16 py-4 flex items-center justify-between relative z-10">
                <Link to="/" className="flex items-center space-x-3">
                    <img
                        src="https://maresone.s3.us-east-1.amazonaws.com/output-onlinepngtools.png"
                        alt="Gaia Logo"
                        className="w-10 h-auto transform hover:scale-105 transition-transform duration-300"
                    />
                    <span className="text-2xl font-semibold">Gaia</span>
                </Link>
                <div className="hidden sm:flex space-x-8 items-center">
                    <Link
                        to="/documentation"
                        className={`flex items-center font-semibold hover:text-blue-500 transition-colors duration-300 ${isDashboardRoute ? 'text-gray-700' : 'text-gray-300 hover:text-white'}`}
                    >
                        <FileText className="w-5 h-5 mr-1" /> Docs
                    </Link>
                    <Link
                        to="/models"
                        className={`flex items-center font-semibold hover:text-blue-500 transition-colors duration-300 ${isDashboardRoute ? 'text-gray-700' : 'text-gray-300 hover:text-white'}`}
                    >
                        <Box className="w-5 h-5 mr-1" /> Models
                    </Link>
                    {user && !isDashboardRoute && (
                        <Link
                            to="/dashboard"
                            className="flex items-center px-4 py-2 rounded-full font-semibold bg-gradient-to-r from-blue-600 to-indigo-500 text-white hover:from-blue-500 hover:to-indigo-400 transition-colors duration-300"
                        >
                            <Home className="w-5 h-5 mr-1" /> Dashboard
                        </Link>
                    )}
                    {user ? (
                        <button
                            className={`flex items-center font-semibold transition-colors duration-300 ${isDashboardRoute ? 'text-red-600 hover:text-red-500' : 'text-red-400 hover:text-red-300'}`}
                            onClick={signOut}
                        >
                            <LogOut className="w-5 h-5 mr-1" /> Sign Out
                        </button>
                    ) : (
                        <button
                            className={`flex items-center px-4 py-2 rounded-full font-semibold transition-colors duration-300 ${isDashboardRoute ? 'bg-blue-600 text-white hover:bg-blue-500' : 'bg-gradient-to-r from-blue-600 to-indigo-500 text-white hover:from-blue-500 hover:to-indigo-400'}`}
                            onClick={signInWithGoogle}
                        >
                            <LogIn className="w-5 h-5 mr-1" /> Sign In
                        </button>
                    )}
                </div>
                <div className="sm:hidden">
                    <button
                        onClick={toggleMenu}
                        className={`focus:outline-none ${isDashboardRoute ? 'text-black hover:text-gray-700' : 'text-white hover:text-gray-300'}`}
                        aria-label="Toggle Menu"
                        aria-expanded={isOpen}
                    >
                        {isOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
                    </button>
                </div>
            </nav>
            {isOpen && (
                <div className={`sm:hidden ${isDashboardRoute ? 'bg-white' : 'bg-gray-900'}`}>
                    <div className="px-4 pt-2 pb-4 space-y-4">
                        <Link
                            to="/documentation"
                            className={`flex items-center font-semibold transition-colors duration-300 ${isDashboardRoute ? 'text-gray-700 hover:text-blue-500' : 'text-gray-300 hover:text-white'}`}
                            onClick={() => setIsOpen(false)}
                        >
                            <FileText className="w-5 h-5 mr-2" /> Docs
                        </Link>
                        <Link
                            to="/models"
                            className={`flex items-center font-semibold transition-colors duration-300 ${isDashboardRoute ? 'text-gray-700 hover:text-blue-500' : 'text-gray-300 hover:text-white'}`}
                            onClick={() => setIsOpen(false)}
                        >
                            <Box className="w-5 h-5 mr-2" /> Models
                        </Link>
                        {user && !isDashboardRoute && (
                            <Link
                                to="/dashboard"
                                className="flex items-center px-4 py-2 rounded-full font-semibold bg-gradient-to-r from-blue-600 to-indigo-500 text-white hover:from-blue-500 hover:to-indigo-400 transition-colors duration-300"
                                onClick={() => setIsOpen(false)}
                            >
                                <Home className="w-5 h-5 mr-2" /> Dashboard
                            </Link>
                        )}
                        {user ? (
                            <button
                                className={`flex items-center w-full font-semibold transition-colors duration-300 ${isDashboardRoute ? 'text-red-600 hover:text-red-500' : 'text-red-400 hover:text-red-300'}`}
                                onClick={() => {
                                    signOut();
                                    setIsOpen(false);
                                }}
                            >
                                <LogOut className="w-5 h-5 mr-2" /> Sign Out
                            </button>
                        ) : (
                            <button
                                className={`flex items-center w-full px-4 py-2 rounded-full font-semibold transition-colors duration-300 ${isDashboardRoute ? 'bg-blue-600 text-white hover:bg-blue-500' : 'bg-gradient-to-r from-blue-600 to-indigo-500 text-white hover:from-blue-500 hover:to-indigo-400'}`}
                                onClick={() => {
                                    signInWithGoogle();
                                    setIsOpen(false);
                                }}
                            >
                                <LogIn className="w-5 h-5 mr-2" /> Sign In
                            </button>
                        )}
                    </div>
                </div>
            )}
        </header>
    );
};

export default Header;
