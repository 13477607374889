import React from 'react';
import { Heart, Github } from 'lucide-react';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer translate="no" className="relative bg-gradient-to-r from-gray-900 via-gray-800 to-black py-8 mt-12 text-white">
            {/* Decorative Elements */}
            <div className="absolute inset-0 bg-gradient-to-r from-blue-900 via-transparent to-purple-900 opacity-20 pointer-events-none"></div>
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
                <div className="flex flex-col items-center justify-between sm:flex-row">
                    <p className="text-gray-400 text-sm">
                        © {currentYear} Gaia. All rights reserved.
                    </p>
                    <div className="flex items-center mt-4 sm:mt-0">
                        <a
                            href="https://github.com/yourusername/your-repo"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-gray-400 hover:text-white transition-colors duration-300 flex items-center"
                        >
                            <Github className="w-5 h-5 mr-2" />
                            GitHub
                        </a>
                        <span className="text-gray-400 text-sm flex items-center ml-6">
                            Made with <Heart className="w-4 h-4 mx-1 text-red-500 animate-pulse" /> in Peru
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
