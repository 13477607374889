import React from 'react';
import { Upload, Globe, Search, Brain, Server, ArrowRight, Terminal } from 'lucide-react';
import Header from './header';
import Footer from "./footer";

const Docs = () => {
    const processes = [
        {
            name: 'Data Upload',
            icon: Upload,
            description: 'Upload your parallel sentences JSON file to provide the training data for your translation model.',
            details: (
                <>
                    Ensure your JSON file contains well-formatted parallel sentences with source and target language pairs. This data serves as the foundation for training your model to understand and generate accurate translations.
                </>
            )
        },
        {
            name: 'Semantic & Keyword Search',
            icon: Search,
            description: 'Retrieve relevant data using semantic and keyword-based search techniques.',
            details: (
                <>
                    The system utilizes both semantic and keyword searches to fetch the most relevant parallel sentences from your dataset. Semantic search understands the context and meaning behind queries, while keyword search matches exact terms, ensuring comprehensive data retrieval for effective model training.
                </>
            )
        },
        {
            name: 'Feeding Data to LLM',
            icon: Brain,
            description: 'Integrate retrieved data with the Large Language Model (LLM) for translation generation.',
            details: (
                <>
                    Retrieved parallel sentences are fed into the LLM, which leverages its extensive training to generate high-quality translations. The LLM uses the contextual and keyword data to produce translations that are both accurate and contextually appropriate.
                </>
            )
        },
        {
            name: 'Model Training',
            icon: Server,
            description: 'Train your translation model using the integrated data.',
            details: (
                <>
                    The translation model undergoes training using the combined data from semantic and keyword searches. This process fine-tunes the model's ability to understand language nuances and produce reliable translations across different language pairs.
                </>
            )
        },
        {
            name: 'Model Deployment',
            icon: Terminal,
            description: 'Deploy your trained model for real-time translation tasks.',
            details: (
                <>
                    Once trained, your model is deployed to a production environment where it can handle real-time translation requests. The deployment ensures that your model is accessible, scalable, and ready to deliver translations efficiently.
                </>
            )
        },
        {
            name: 'Continuous Improvement',
            icon: ArrowRight,
            description: 'Iterate and enhance your model based on feedback and new data.',
            details: (
                <>
                    Continuously monitor your model's performance and incorporate new parallel sentences to keep improving translation accuracy. Regular updates and retraining help maintain the model's relevance and effectiveness in handling diverse translation scenarios.
                </>
            )
        }
    ];

    return (
        <div translate="no" className='bg-gradient-to-b from-gray-900 via-gray-800 to-black text-white min-h-screen'>
            <div className="max-w-4xl pt-16 mx-auto p-6">
                <div className="mb-8">
                    <h1 className="text-3xl font-bold text-white mb-4">Gaia Translation Model Documentation</h1>
                    <p className="mb-4">
                        Welcome to the Gaia documentation guide. This resource will help you understand the key processes involved in building and deploying your custom translation models using our platform.
                    </p>
                    <p className="mb-4">
                        Understanding these processes is crucial for effectively utilizing Gaia's capabilities, ensuring that you can create, customize, and deploy models that meet your specific translation needs.
                    </p>
                    <p>
                        Let's explore each step in detail:
                    </p>
                </div>

                <div className="space-y-8">
                    {processes.map((process, index) => (
                        <div key={index} className="p-6 bg-gray-800 rounded-xl shadow-md">
                            <div className="flex items-center mb-4">
                                <process.icon className="w-8 h-8 mr-4 text-blue-500" />
                                <h3 className="text-xl font-semibold text-white">{process.name}</h3>
                            </div>
                            <p className="text-white mb-3">{process.description}</p>
                            <p className="text-sm text-gray-300">{process.details}</p>
                        </div>
                    ))}
                </div>

                <div className="mt-8 p-6 bg-gray-800 rounded-xl shadow-md">
                    <h3 className="text-xl font-semibold text-blue-400 mb-4">Putting It All Together</h3>
                    <p className="text-gray-300 mb-3">
                        Each step in the process is interconnected to ensure that your translation model is both accurate and efficient. From uploading your data to deploying the trained model, Gaia provides a seamless workflow that leverages advanced technologies to deliver high-quality translations.
                    </p>
                    <p className="text-gray-300 mb-3">
                        By understanding and following these processes, you can optimize your model's performance, adapt to new translation challenges, and continuously improve the quality of your translations.
                    </p>
                    <p className="text-gray-300">
                        Gaia's infrastructure supports your translation needs, enabling you to focus on creating impactful models without worrying about the underlying complexities.
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Docs;
