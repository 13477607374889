import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';
import { signInWithPopup, signInWithEmailAndPassword } from 'firebase/auth';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { auth, googleProvider, db } from "./firebase";
import Home from "./components/home";
import Models from "./components/models";
import Docs from "./components/docs";
import Dashboard from "./components/dashboard";
import ModelDetail from "./components/modelDetail";
import Header from "./components/header";
import PublicModel from "./components/publicModel";

function App() {
  const [user] = useAuthState(auth);
  const [models, setModels] = useState([]);
  const [publicModels, setPublicModels] = useState([]);

  useEffect(() => {
    const fetchModels = async () => {
      if (user) {
        try {
          const modelsRef = collection(db, "models");
          const q = query(modelsRef, where("uid", "==", user.uid));
          const querySnapshot = await getDocs(q);
          const modelsList = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setModels(modelsList);
          console.log("Models: ", modelsList);
        } catch (error) {
          console.error("Error fetching models:", error);
        }
      }
    };

    fetchModels();
  }, [user]);

  const fetchModels = async () => {
    if (user) {
      try {
        const modelsRef = collection(db, "models");
        const q = query(modelsRef, where("uid", "==", user.uid));
        const querySnapshot = await getDocs(q);
        const modelsList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setModels(modelsList);
        console.log("Models: ", modelsList);
      } catch (error) {
        console.error("Error fetching models:", error);
      }
    }
  };

  useEffect(() => {
    const fetchPublicModels = async () => {
      try {
        const modelsRef = collection(db, "models");
        const q = query(modelsRef, where("public", "==", true));
        const querySnapshot = await getDocs(q);
        const publicModelsList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setPublicModels(publicModelsList);
        console.log("Public Models: ", publicModelsList);
      } catch (error) {
        console.error("Error fetching public models:", error);
      }
    };

    fetchPublicModels();
  }, []);

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log("User signed in with Google:", result);
    } catch (error) {
      console.error("Google sign-in error:", error);
    }
  };

  const signInWithEmail = async (email, password) => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      console.log("User signed in with email:", result);
    } catch (error) {
      console.error("Email/password sign-in error:", error);
    }
  };

  const signOut = () => {
    auth.signOut();
  };

  const [sotaModels, setSotaModels] = useState([]);

  useEffect(() => {
    const fetchSotaModels = async () => {
      if (user) {
        try {
          const sotaRef = collection(db, "sota");
          const q = query(sotaRef, where("uid", "==", user.uid));
          const querySnapshot = await getDocs(q);
          const sotaModelsList = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setSotaModels(sotaModelsList);
        } catch (error) {
          console.error("Error fetching sota models:", error);
        }
      }
    };

    fetchSotaModels();
  }, [user]);

  return (
    <Router>
      <Header user={user} signInWithGoogle={signInWithGoogle} signInWithEmail={signInWithEmail} signOut={signOut} />
      <Routes>
        <Route path="/" element={<Home user={user} signInWithGoogle={signInWithGoogle} signInWithEmail={signInWithEmail} />} />
        {user && (
          <>
            <Route
              path="/dashboard"
              element={
                <Dashboard
                  user={user}
                  models={models}
                  sotaModels={sotaModels}
                />
              }
            />
            <Route
              path="/dashboard/:modelId"
              element={<ModelDetail />}
            />
          </>
        )}
        <Route path="/documentation" element={<Docs />} />
        <Route path="/models" element={<Models publicModels={publicModels} />} />
        <Route path="/models/:modelId" element={<PublicModel />} />
      </Routes>
    </Router>
  );
}

export default App; 